import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-toastify/dist/ReactToastify.css';
import './styles/common.scss';
import './services/i18n';

import Client from 'fhirclient/lib/Client';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import App from './App';
import MyMhBanner from './components/MyMhBanner/MyMhBanner';
import store from './reduxToolkit/store';
import reportWebVitals from './reportWebVitals';
import { smartLaunch } from './services/fhirService';
import { isMobileApp, setViewFlag } from './services/utilities';

// import EclBanner from './components/MyMhBanner/EclBanner';

const browserView = !isMobileApp();
const renderApp = (fhirClient?: Client, lang?: string | null) => {
  setViewFlag();
  const rootElement = document.getElementById('root');
  if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <h1 className='d-none'>My MemorialHermann</h1>
          <div id='my-mh' className={!browserView ? 'mobile-app' : ''}>
            <div className='container w-100 g-0'>
              <div>
                {browserView && (
                  <header className='top-banner p-0'>
                    <div className='top-mh-banner'>
                      <MyMhBanner />
                    </div>
                    {/* <div className='top-ecl-banner'>
                    <EclBanner />
                  </div> */}
                  </header>
                )}
                <main className='w-100 p-0 m-0 d-flex flex-column'>
                  <ToastContainer position='bottom-center' />
                  <Router>
                    <Suspense fallback='loading ...'>
                      <App fhirClient={fhirClient} lang={lang} />
                    </Suspense>
                  </Router>
                </main>
              </div>
            </div>
          </div>
        </Provider>
      </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  } else {
    console.error('Failed to get element by id "root"');
  }
};

smartLaunch(renderApp);

