import 'bootstrap/dist/css/bootstrap.css';
import './home.scss';

import Client from 'fhirclient/lib/Client';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import config from '../../config/config';
import { KEY_debug, KEY_patient, KEY_patient_name } from '../../constants';
import { selectLanguage } from '../../reduxToolkit/features/languageSlice';
import { getImageSrcset, toastError } from '../../services/appService';
import {
    ContentDictionary, getContentDictionaryInCurrentLanguage
} from '../../services/contentService';
import { getPatientName } from '../../services/epicServices';
import { readFromStorage, removeFromStorage, writeToStorage } from '../../services/storageServies';
import {
    getRandomImage, isAndroidApp, isIosApp, isMobileApp, isPrintingSupported, isTouchCapable
} from '../../services/utilities';
import { ImageSrcSet, Patient } from '../../types/interfaces';
import { HealthBlog } from '../HealthBlog/HealthBlog';
import MyMhBanner from '../MyMhBanner/MyMhBanner';
import Card from '../shared/Card/Card';
import RoundActionBox from '../shared/RoundActionBox/RoundActionBox';
import MyMhSpinner from '../shared/Spinner/MyMhSpinner';
import SquareActionBox from '../shared/SquareActionBox/SquareActionBox';
import Survey from '../Survey/Survey';
import billPayIcon from './Bills Icon.svg';
import costEstimateIcon from './Calculator Icon.svg';
import schedulingIcon from './Calendar Icon.svg';
import getCareIcon from './Clock Icon.svg';
// import findCareImg from './find-care-md.jpg';
import myMemorialHermannIcon from './Home Screen Logo.svg';
// import image1 from './image1-md.jpg';
// import image2 from './image2-md.jpg';
// import image3 from './image3-md.jpg';
// import image4 from './image4-md.jpg';
import labResultIcon from './Labs Icon.svg';

const Home = ({ fhirClient: client }: { fhirClient?: Client }) => {
  const history = useHistory();
  const [callingAPI, setCallingAPI] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | null | undefined>(null);


  // const [headImage, setHeadImage] = useState<string | undefined>(undefined);
  const [headImageSrcSet, setHeadImageSrcSet] = useState<ImageSrcSet | undefined>(undefined);
  const [findCareImageSrcSet, setFindCareImageSrcSet] = useState<ImageSrcSet | undefined>(undefined);
  const [contentDictionary, setContentDictionary] =
    useState<ContentDictionary | null>(null);
  const [dbgMsg, setDbgMsg] = useState<string | null>(null);

  const noName = 'noName';
  const browserView = !isMobileApp();

  useEffect(() => {
    // setHeadImage(getRandomImage([image1, image2, image3, image4]));
    const headImg = getRandomImage(['/images/home/image1.jpg', '/images/home/image2.jpg', '/images/home/image3.jpg', '/images/home/image4.jpg']);
    const headImgSpec = getImageSrcset({ imageName: headImg! });
    const findCareImgSpec = getImageSrcset({ imageName: '/images/home/find-care.jpg' });
    // if (!browserView) {          
    //   const mobileScreenImageSizes = getMobileImageSizes();
    //   headImgSpec.sizes = mobileScreenImageSizes;
    //   findCareImgSpec.sizes = mobileScreenImageSizes;
    // }
    setHeadImageSrcSet(headImgSpec);
    setFindCareImageSrcSet(findCareImgSpec);

    let patientName = readFromStorage<string | null | undefined>(KEY_patient_name);
    if (patientName) {
      setUserName(patientName);
    }
    else {
      const patient = readFromStorage<Patient>(KEY_patient);
      if (patient) {
        patientName = getPatientName({ patient, type: 'Given' });
        setUserName(patientName);
      }
      else if (client) {
        setCallingAPI(true);
        client.patient.read().then((patient: Patient) => {
          writeToStorage<Patient>(KEY_patient, patient);
          patientName = getPatientName({ patient, type: 'Given' });
          setUserName(patientName);
        }).catch((e) => {
          console.error(`Failed to retrieve FHIR patient due to: ${JSON.stringify(e)}`);
          patientName = noName;
          setUserName(patientName);
          writeToStorage(KEY_patient_name, patientName);
        }
        ).finally(() => setCallingAPI(false));
      }
      else {
        console.log('No FHIR client is available. Patient-only experience is disabled.');
      }
    }
  }, []);

  const language = useSelector(selectLanguage);
  useEffect(() => {
    const content = getContentDictionaryInCurrentLanguage('home');
    if (!content) {
      console.warn('Failed to load the content dictionary.');
    }
    setContentDictionary(content);
  }, [language]);

  // const getMobileImageSizes = () => {
  //   const sizesArray: string[] = DefaultScreenSizes.length == 1 ? [`${DefaultScreenSizes[0]}px` ]: DefaultScreenSizes.slice(0, DefaultScreenSizes.length-1).map(size=>`(max-width: ${size.width}px) 100vw`);
  //   if (DefaultScreenSizes.length > 1) { sizesArray.push(`100vw`); }
  //   const sizesStr = sizesArray.join(', ');
  //   return sizesStr;
  // };

  const onLogoClicked = () => {
    setDbgMsg(null);
    removeFromStorage(KEY_debug);
  };

  const gotoMyChart = () => {
    if (userName)
      history.push(config.urlConfig.gotoMychartHome);
    else
      history.push(config.urlConfig.gotoMychartLogin);
  };

  const gotoTestResult = () => {
    history.push(config.urlConfig.gotoTestResult);
  };

  const displayDebuggingInfo = () => {
    const env = (config.env || '').toLowerCase();
    if (env == 'prd' || env == 'prod') {
      return;
    }
    writeToStorage(KEY_debug, 'true');
    const info = {
      Env: config.env,
      UserName: userName,
      PreLoginUrl: window.location.href,
      IsIosApp: isIosApp(),
      IsAndroidApp: isAndroidApp(),
      UserAgents: window.navigator.userAgent,
      SupportWebAuthn: typeof PublicKeyCredential != 'undefined',
      SupportTouch: isTouchCapable(),
      SupportPrint: isPrintingSupported(),
      BlockItUrlConfig: {
        regular: config.urlConfig.blockItUrl,
        urgent: config.urlConfig.blockItQuickCareUrl,
      },
    };
    const msg = JSON.stringify(info);
    setDbgMsg(msg);
  };

  const gotoPayBill = () => {
    history.push(userName ? config.urlConfig.payBill : config.urlConfig.payAsGuest);
  };

  const gotoCostEstimate = () => {
    history.push(userName ? config.urlConfig.costEstimate : config.urlConfig.guestCostEstimate);
  };

  const onImageClicked = () => {
  };

  const getLabel = () => {
    return userName == noName ? `Your ${contentDictionary?.myChartLabel}` : `${userName}'s ${contentDictionary?.myChartLabel}`;
  };

  const renderHeaderCard = () => (
    <Card>
      <div className='head-frame' onClick={() => onImageClicked()}>
        <div className='head-frame-border'>
          <div className='head-frame-content'>
            <img
              src={headImageSrcSet!.src}
              srcSet={headImageSrcSet!.srcSet}
              sizes={headImageSrcSet!.sizes}
              className='head-image'
              alt={contentDictionary?.imageLabel}
            />
          </div>
        </div>
      </div>
      <div className='logo-frame padding-x'>
        <img
          src={myMemorialHermannIcon}
          className='my-mh-logo'
          alt={contentDictionary?.mhLogoLabel}
          onClick={onLogoClicked}
          loading='lazy'
        />
      </div>
    </Card>
  );

  const renderLoginCard = () => (
    <Card>
      <div className='portal-login padding-x'>
        <button className='btn primary' onClick={() => gotoMyChart()}>
          {userName ? getLabel() : contentDictionary?.loginToMyChart}
        </button>
      </div>
    </Card>
  );

  const renderFindCareCard = () => (
    <Card>
      <div className='find-care-heading padding-x'>
        {contentDictionary?.findCareLabel}
      </div>
      <div className='find-care-container-spacing'>
        <div className='find-care-container'>
          <div className='find-care-image-container'>
            <img
              src={findCareImageSrcSet!.src}
              srcSet={findCareImageSrcSet!.srcSet}
              sizes={findCareImageSrcSet!.sizes}
              alt='Find Care'
              className='find-care-image'
              onClick={() => displayDebuggingInfo()}
              loading='lazy'
            />
          </div>
          <div className='find-care-actions'>
            <SquareActionBox
              iconPath={schedulingIcon}
              label={contentDictionary?.scheduleNowLabel}
              onClick={() =>
                history.push(
                  config.urlConfig.scheduling,
                )
              }
            />
            <SquareActionBox
              iconPath={getCareIcon}
              label={contentDictionary?.getQuickCareLabel}
              onClick={() => history.push(config.urlConfig.getCareNow)}
            />
          </div>
        </div>
      </div>
    </Card>
  );

  const renderMiscServicesCard = () => (
    <Card extraCalss='flex-expand'>
      <div className='footer'>
        <div className='footer-frame padding-x'>
          <div className='w-33'>
            <RoundActionBox
              iconPath={labResultIcon}
              label={contentDictionary?.testResultsLabel}
              onClick={gotoTestResult}
            />
          </div>
          <div className='w-33'>
            <RoundActionBox
              iconPath={billPayIcon}
              label={
                userName ? contentDictionary?.payBillLabel : contentDictionary?.payAsGuestLabel
              }
              onClick={gotoPayBill}
            />
          </div>
          <div className='w-33'>
            <RoundActionBox
              iconPath={costEstimateIcon}
              label={contentDictionary?.estimatCostsLabel}
              onClick={gotoCostEstimate}
            />
          </div>
        </div>
      </div>
    </Card>
  );

  const loading = callingAPI || !contentDictionary;

  if (loading)
    return <MyMhSpinner />;


  return (
    <div className='w-100 h-100 row no-top-banner'>
      <h2 className='d-none'>Home</h2>
      {browserView && (
        <aside className='col-6 d-none d-lg-block p-0 m-0 side-banner'>
          <MyMhBanner />
        </aside>
      )}
      <div className={`p-0 col-12 ${browserView ? 'col-lg-6' : ''}`}>
        <div className='w-100 h-100 position-relative d-flex flex-column justify-content-start align-items-center'>
          {loading && <MyMhSpinner />}
          {loading && <div className='w-100 screen-cover'></div>}
          <section className='w-100'>
            {renderHeaderCard()}
          </section>
          <section className='w-100'>
            {renderLoginCard()}
          </section>
          <hr className='divider' />
          <section className='w-100'>
            {renderFindCareCard()}
          </section>
          <hr className='divider' />
          <section className='w-100'>
            {renderMiscServicesCard()}
          </section>
          {/* <hr className='divider' /> */}
          <section className='w-100 blogs-block'>
            <div className='w-100 padding-x blogs-block-headline mb-16'>
              {contentDictionary?.healthBlog}
            </div>
            {/* {
              browserView ? <HealthBlog
                carouselType='Scroll'
                looping={false}
                onError={
                  () => {
                    toastError(contentDictionary?.healthBlogUnavailable || "Health & Wellness is unavaible at this moment. Please try again later.", 3000);
                  }}
              />
                :
                <HealthBlog
                  carouselType='Snapshot'
                  looping={true}
                  onError={
                    () => {
                      toastError(contentDictionary?.healthBlogUnavailable || "Health & Wellness is unavaible at this moment. Please try again later.", 3000);
                    }}
                />
            } */}
            <HealthBlog
              // carouselType='Snapshot'
              // looping={true}
              carouselType='Scroll'
              looping={false}
              onError={
                () => {
                  toastError(contentDictionary?.healthBlogUnavailable || "Health & Wellness is unavaible at this moment. Please try again later.", 3000);
                }}
            />
          </section>
          <section className='w-100  padding-x'>
            <Survey />
          </section>
          <section className='w-100 home-support padding-x'>
            {contentDictionary?.help}
            <a className='link' href={config.urlConfig.supportUrl} target='_blank' rel='noreferrer'>{contentDictionary?.helpLink}</a>
          </section>
          <section className='w-100 mt-16 home-disclaimer padding-x'>
            {contentDictionary?.disclaimer}
          </section>
          {dbgMsg && (
            <div className='w-100'>
              <div className='w-100 mb-12 alert alert-warning'>
                <strong>
                  For Debugging only. QA team please ignore the part below. Click
                  on the MH logo to dismiss this part.
                </strong>
              </div>
              <div className='w-100 padding-x mb-12'>{dbgMsg}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
