import './Survey.scss';

import React from 'react';

import config from '../../config/config';
import { redirectToUrl } from '../../services/utilities';

const Survey = (): JSX.Element => {
  const { urlConfig } = config;

  return (
    <>
      <div className='survey-header'> Help us improve the app experience </div>
      <div className='survey-content'>Share your feedback on the My Memorial Hermann™ app with this quick two-minute survey.</div>
      <div className='w-100'>
        <button className='btn survey-button' onClick={() => redirectToUrl(urlConfig.surveyLink, 'new')} >Leave Feedback</button>
      </div>
    </>
  );
};

export default Survey;
