import './UrgentCare.scss';

import React from 'react';

import config from '../../config/config';
import { isMobileApp } from '../../services/utilities';
import IFramedScreen from '../shared/IFramedScreen/IFramedScreen';

// import MyMhBanner from '../MyMhBanner/MyMhBanner';

const UrgentCare = (): JSX.Element => {
  const { pageNames, urlConfig } = config;
  const browserView = !isMobileApp();

  return (
    <IFramedScreen
      pageName={pageNames.urgentCare || ''}
      // showBackButton={true}
      // showHomeButton={!browserView}
      showBackButton={browserView}
      showHomeButton={false}
      url={urlConfig.goHealthUcUrl}
      extraClass='no-padding'
    // height={IFrameDefaultHeight}
    />
  );
};

export default UrgentCare;
